import { SbBlokData, storyblokEditable } from "@storyblok/react";

export interface ServiceComponent extends SbBlokData {
  _uid: string;
  icon: string;
  icon_background_color: string;
  title: string;
  description: string;
}

const Service = ({ blok }: { blok: ServiceComponent }) => (
  <div className="first:lg:pt-12 last:lg:pt-6 w-full md:w-4/12 px-4 text-center" {...storyblokEditable(blok)}>
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
      <div className="px-4 py-5 flex-auto">
        <div className={`text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-${blok.icon_background_color}-400`}>
          <i className={`fas ${blok.icon}`}></i>
        </div>
        <h6 className="text-xl font-semibold">{blok.title}</h6>
        <p className="mt-2 mb-4 text-gray-600">{blok.description}</p>
      </div>
    </div>
  </div>
);

export default Service;
