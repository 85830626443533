import Image from "next/image";
import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "@storyblok/react";
import { FeaturedListItemComponent } from "./FeaturedListItem";
import { SbImage, getImageUrl } from "./storyblok/SbImage";

interface FeaturedComponent extends SbBlokData {
  image: SbImage;
  icon: string;
  icon_background_color: string;
  title: string;
  description: string;
  list: FeaturedListItemComponent[];
}

const Featured = ({ blok }: { blok: FeaturedComponent }) => (
  <section className="relative py-20" {...storyblokEditable(blok)}>
    <div
      className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
      style={{ height: "80px" }}
    >
      <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div className="container mx-auto px-4">
      <div className="items-center flex flex-wrap">
        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
          <Image
            alt="..."
            className="max-w-full rounded-lg shadow-lg"
            src={getImageUrl(blok.image)}
            width="634"
            height="951"
          />
        </div>
        <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div className="md:pr-12">
            <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
              <i className={`fas ${blok.icon} text-xl`}></i>
            </div>
            <h3 className="text-3xl font-semibold">{blok.title}</h3>
            <p className="mt-4 text-lg leading-relaxed text-gray-600">
              {blok.description}
            </p>
            <ul className="list-none mt-6">
              {blok.list?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Featured;
