import { SbBlokData, storyblokEditable } from "@storyblok/react";

export interface FinisherListItemComponent extends SbBlokData {
  _uid: string;
  icon: string;
  title: string;
  description: string;
}

const FinisherListItem = ({ blok }: { blok: FinisherListItemComponent }) => (
  <div
    className="w-full lg:w-3/12 px-4 text-center"
    {...storyblokEditable(blok)}
  >
    <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
      <i className={`fas ${blok.icon} text-xl`}></i>
    </div>
    <h6 className="text-xl mt-5 font-semibold text-white">{blok.title}</h6>
    <p className="mt-2 mb-4 text-gray-500">{blok.description}</p>
  </div>
);

export default FinisherListItem;
