import Image from "next/image";
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { SbImage, getImageUrl } from "./storyblok/SbImage";

interface SbLink {
  cached_url: string;
}

export interface TeamMemberComponent extends SbBlokData {
  _uid: string;
  name: string;
  profile_picture: SbImage;
  job_title: string;
  twitter: SbLink;
  facebook: SbLink;
  instagram: SbLink;
}

function do_construct_link(linkObject: SbLink | undefined) {
  let prefix = "/";

  if (typeof linkObject === "undefined") {
    return prefix;
  }

  // Handle external URL
  if (linkObject.cached_url.startsWith("http")) {
    return linkObject.cached_url;
  }

  return prefix + linkObject.cached_url;
}

const TeamMember = ({ blok }: { blok: TeamMemberComponent }) => (
  <div
    className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4"
    {...storyblokEditable(blok)}
  >
    <div className="px-6">
      <Image
        alt="..."
        src={getImageUrl(blok.profile_picture)}
        width="800"
        height="800"
        className="shadow-lg rounded-full max-w-full mx-auto"
        style={{ maxWidth: "120px" }}
      />
      <div className="pt-6 text-center">
        <h5 className="text-xl font-bold">{blok.name}</h5>
        <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
          {blok.job_title}
        </p>
        <div className="mt-6">
          {blok.twitter.cached_url && (
            <a href={do_construct_link(blok.twitter)}>
              <button
                className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                type="button"
              >
                <i className="fab fa-twitter"></i>
              </button>
            </a>
          )}
          {blok.facebook.cached_url && (
            <a href={do_construct_link(blok.facebook)}>
              <button
                className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                type="button"
              >
                <i className="fab fa-facebook-f"></i>
              </button>
            </a>
          )}
          {blok.instagram.cached_url && (
            <a href={do_construct_link(blok.instagram)}>
              <button
                className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                type="button"
              >
                <i className="fab fa-instagram"></i>
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default TeamMember;
