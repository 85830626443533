import { SbBlokData, storyblokEditable } from "@storyblok/react";

export interface FeaturedListItemComponent extends SbBlokData {
  _uid: string;
  icon: string;
  text: string;
}

const FeaturedListItem = ({ blok }: { blok: FeaturedListItemComponent }) => (
  <li className="py-2" {...storyblokEditable(blok)}>
    <div className="flex items-center">
      <div>
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
          <i className={`fas ${blok.icon}`}></i>
        </span>
      </div>
      <div>
        <h4 className="text-gray-600">{blok.text}</h4>
      </div>
    </div>
  </li>
);

export default FeaturedListItem;
