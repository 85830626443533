import Image from "next/image";
import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "@storyblok/react";
import { FinisherListItemComponent } from "./FinisherListItem";

interface FinisherComponent extends SbBlokData {
  title: string;
  description: string;
  list: FinisherListItemComponent[];
}

const Finisher = ({ blok }: { blok: FinisherComponent }) => (
  <section
    className="pb-20 relative block bg-gray-900"
    {...storyblokEditable(blok)}
  >
    <div
      className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
      style={{ height: "80px" }}
    >
      <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-gray-900 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold text-white">{blok.title}</h2>
          <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
            {blok.description}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap mt-12 justify-center">
        {blok.list?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default Finisher;
