import Image from "next/image";
import { ServiceComponent } from "./Service";
import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from "@storyblok/react";
import { SbImage, getImageUrl } from "./storyblok/SbImage";

interface SbLink {
  cached_url: string;
}

interface ServicesComponent extends SbBlokData {
  body: ServiceComponent[];
  featured_icon: string;
  featured_title: string;
  featured_description: string;
  featured_image: SbImage;
  featured_title_2: string;
  featured_description_2: string;
  cta_link: SbLink;
  cta_text: string;
}

function do_construct_link(linkObject: SbLink | undefined) {
  let prefix = "/";

  if (typeof linkObject === "undefined") {
    return prefix;
  }

  // Handle external URL
  if (linkObject.cached_url.startsWith("http")) {
    return linkObject.cached_url;
  }

  return prefix + linkObject.cached_url;
}

const Services = ({ blok }: { blok: ServicesComponent }) => {
  return (
    <section className="pb-20 bg-gray-300 -mt-24" {...storyblokEditable(blok)}>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          {blok.body.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>

        <div className="flex flex-wrap items-center mt-32">
          <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
            <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
              <i className={`fas ${blok.featured_icon} text-xl`}></i>
            </div>
            <h3 className="text-3xl mb-2 font-semibold leading-normal">
              {blok.featured_title}
            </h3>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
              {blok.featured_description}
            </p>
            <a
              href={do_construct_link(blok.cta_link)}
              className="font-bold text-gray-800 mt-8"
            >
              {blok.cta_text}
            </a>
          </div>

          <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg bg-pink-600">
              <Image
                alt="..."
                src={getImageUrl(blok.featured_image)}
                className="w-full align-middle rounded-t-lg"
                width="1051"
                height="701"
              />
              <blockquote className="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  className="absolute left-0 w-full block"
                  style={{
                    height: "95px",
                    top: "-94px",
                  }}
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    className="text-pink-600 fill-current"
                  ></polygon>
                </svg>
                <h4 className="text-xl font-bold text-white">
                  {blok.featured_title_2}
                </h4>
                <p className="text-md font-light mt-2 text-white">
                  {blok.featured_description_2}
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
