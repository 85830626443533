import Image from "next/image";
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { SbImage, getImageUrl } from "./storyblok/SbImage";

interface SbLink {
  cached_url: string;
}

interface cHeader extends SbBlokData {
  title: string;
  description: string;
  cta_text: string;
  cta_link: SbLink;
  learn_more_link: SbLink;
  image: SbImage;
}

function do_construct_link(linkObject: SbLink | undefined) {
  let prefix = "/";

  if (typeof linkObject === "undefined") {
    return prefix;
  }

  // Handle external URL
  if (linkObject.cached_url.startsWith("http")) {
    return linkObject.cached_url;
  }

  return prefix + linkObject.cached_url;
}

const Header = ({ blok }: { blok: cHeader }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0"
    >
      <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                New Colaboration
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              {blok.title}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {blok.description}
            </p>
          </div>
          <div className="flex flex-col items-center md:flex-row">
            <a
              href={`${do_construct_link(blok.cta_link)}`}
              className="inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              {blok.cta_text}
            </a>
            <a
              href={`${do_construct_link(blok.learn_more_link)}`}
              aria-label=""
              className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <div className="inset-y-0 right-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
        <Image
          layout="fill"
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
          src={getImageUrl(blok.image)}
          alt=""
        />
      </div>
    </div>
  );
};

export default Header;
