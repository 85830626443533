import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "@storyblok/react";
import { TeamMemberComponent } from "./TeamMember";

interface TeamComponent extends SbBlokData {
  headline: string;
  subheadline: string;
  list: TeamMemberComponent[];
}

const Team = ({ blok }: { blok: TeamComponent }) => (
  <section className="pt-20 pb-48" {...storyblokEditable(blok)}>
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-center text-center mb-24">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold">{blok.headline}</h2>
          <p className="text-lg leading-relaxed m-4 text-gray-600">
            {blok.subheadline}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap">
        {blok?.list?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default Team;
