import "../styles/globals.css";
import type { AppProps } from "next/app";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { storyblokInit, apiPlugin } from "@storyblok/react";

import Contact from "../components/Contact";
import Feature from "../components/Feature";
import Featured from "../components/Featured";
import FeaturedListItem from "../components/FeaturedListItem";
import Finisher from "../components/Finisher";
import FinisherListItem from "../components/FinisherListItem";
import Grid from "../components/Grid";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Page from "../components/Page";
import Service from "../components/Service";
import Services from "../components/Services";
import Team from "../components/Team";
import TeamMember from "../components/TeamMember";
import Teaser from "../components/Teaser";

const components = {
  contact: Contact,
  feature: Feature,
  featured: Featured,
  featured_list_item: FeaturedListItem,
  finisher: Finisher,
  finisher_list_item: FinisherListItem,
  grid: Grid,
  header: Header,
  hero: Hero,
  service: Service,
  services: Services,
  team: Team,
  team_member: TeamMember,
  teaser: Teaser,
  page: Page,
};

storyblokInit({
  accessToken: "U8W7JIKmdto2v4QDF25F7Qtt",
  use: [apiPlugin],
  components,
});

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

export default MyApp;
